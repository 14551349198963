import React, { useState } from 'react'
import {
  Grid2,
  IconButton,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, UseFormReturn } from 'react-hook-form'
import MButton from '../components/material/MButton'
import { yupResolver } from '@hookform/resolvers/yup'
import { addOrderValidation } from '../utils/validation'
import useOrdersStore from '../store/useOrdersStore'
import ConfirmPrintDialog from '../components/ConfirmPrintDialog'
import { Order } from '../types/order'
import { Check } from '@mui/icons-material'
import AddContractorForm from '../components/AddOrderForm/AddContractorForm'
import OrderDetailsForm from '../components/AddOrderForm/OrderDetailsForm'
import AdditionalInformationForm from '../components/AddOrderForm/AdditionalInformationForm'
import useImagesStore from '../store/useImagesStore'
import useSketchesStore from '../store/useSketchesStore'
import GenerateDocumentDialog from '../components/GenerateDocumentDialog'
import useDocumentsStore from '../store/useDocumentsStore'
import { find, map } from 'lodash'
import RouteGuardDialog from '../components/RouteGuardDialog'

const Container = styled('div')(() => ({
  padding: '1rem',
  height: '100%',
}))

const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
}))

const steps = ['Kontrahent', 'Dane zlecenia', 'Dodatkowe informacje']

const StepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 0,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0d539e',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0d539e',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 2,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}))

const StepperConnectorIconRoot = styled('div')<{
  ownerState: { active?: boolean }
}>(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 2,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#0d539e',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#0d539e',
      },
    },
  ],
}))

function StepperConnectorIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <StepperConnectorIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </StepperConnectorIconRoot>
  )
}

export default function AddOrderPage() {
  const navigate = useNavigate()
  const orderId = useParams().orderId
  const { orders, addOrder, updateOrder } = useOrdersStore()
  const { uploadImage } = useImagesStore()
  const { uploadSketch } = useSketchesStore()
  const {
    setActiveGrnNumber,
    addDocumentOrder,
    generateDocument,
    setActiveTaxId,
  } = useDocumentsStore()
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] = useState(false)
  const [openDocumentGeneratorDialog, setOpenDocumentGeneratorDialog] =
    useState(false)
  const [newOrder, setNewOrder] = useState<Order | null>(null)
  const [activeStep, setActiveStep] = useState(0)
  const [elementToPrint, setElementToPrint] = useState<Order | null>(null)

  const editedObject = find(orders, (order) => order.id === Number(orderId))

  const methods = useForm({
    defaultValues: {
      name: editedObject?.name || '',
      taxId: editedObject?.taxId || '',
      postalCode: editedObject?.postalCode || '',
      city: editedObject?.city || '',
      road: editedObject?.road || '',
      ralColor: editedObject?.ralColor || '',
      ciNumber: editedObject?.ciNumber || '',
      date: editedObject?.date || Date.now(),
      phoneNumber: editedObject?.phoneNumber || '',
      base: editedObject?.base || 'no',
      sandblasting: editedObject?.sandblasting || 'no',
      signature: '',
      glossLevel: editedObject?.glossLevel || '',
      finishing: editedObject?.finishing || '',
      paintType: editedObject?.paintType || '',
      description: editedObject?.description || '',
      product: editedObject?.product || '',
      quantity: editedObject?.quantity || 0,
      quantityType: editedObject?.quantityType || '',
    },
    resolver: yupResolver(addOrderValidation),
    mode: 'all',
  })

  const { handleSubmit, formState, watch, reset } = methods

  const confirmPrintDialogControl = (order: Order | null, isOpen: boolean) => {
    setElementToPrint(order)
    setOpenConfirmPrintDialog(isOpen)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const addAnotherOrderToDocument = () => {
    if (newOrder) {
      if (newOrder?.taxId) {
        setActiveTaxId(newOrder?.taxId)
      }
      setActiveGrnNumber(newOrder.ciNumber)
      addDocumentOrder(newOrder).then(() => {
        reset()
        setActiveStep(0)
        setOpenDocumentGeneratorDialog(false)
      })
    }
  }

  const finishDocument = async (signature: string) => {
    if (newOrder) {
      await addDocumentOrder(newOrder)
      await generateDocument(signature)
      setOpenDocumentGeneratorDialog(false)
      reset()
      navigate('/')
    }
  }

  const isValidFirstStep = () => {
    const name = watch('name')
    const phoneNumber = watch('phoneNumber')
    const postalCode = watch('postalCode')
    const city = watch('city')
    const road = watch('road')

    return (
      name.length > 0 &&
      phoneNumber.length > 0 &&
      postalCode.length > 0 &&
      city.length > 0 &&
      road.length > 0
    )
  }

  const isValidSecondStep = () => {
    const ralColor = watch('ralColor')
    const glossLevel = watch('glossLevel')
    const finishing = watch('finishing')
    const paintType = watch('paintType')
    const base = watch('base')
    const sandblasting = watch('sandblasting')
    const ciNumber = watch('ciNumber')
    const signature = watch('signature')
    const product = watch('product')
    const quantity = watch('quantity')
    const quantityType = watch('quantityType')

    return (
      ralColor.length > 0 &&
      glossLevel.length > 0 &&
      finishing.length > 0 &&
      paintType.length > 0 &&
      base.length > 0 &&
      sandblasting.length > 0 &&
      ciNumber.length > 0 &&
      product.length > 0 &&
      quantity > 0 &&
      quantityType.length > 0 &&
      (editedObject ? true : signature && signature.length > 0)
    )
  }

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          {orderId ? 'Edytuj zlecenie' : 'Dodaj zlecenie'}
        </Typography>
      </HeaderContainer>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepperConnector />}
      >
        {map(steps, (label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepperConnectorIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormContainer>
        {activeStep === 0 ? (
          <AddContractorForm
            methods={methods as unknown as UseFormReturn<Order>}
          />
        ) : activeStep === 1 ? (
          <OrderDetailsForm
            methods={methods as unknown as UseFormReturn<Order>}
          />
        ) : (
          <AdditionalInformationForm
            methods={methods as unknown as UseFormReturn<Order>}
          />
        )}
        {activeStep === 0 ? (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              width: '100%',
            }}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleNext}
              disabled={!isValidFirstStep()}
            >
              Następny
            </MButton>
          </Grid2>
        ) : activeStep === 1 ? (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              gap: '1rem',
            }}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleBack}
            >
              Wróć
            </MButton>
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleNext}
              disabled={!isValidSecondStep()}
            >
              Następny
            </MButton>
          </Grid2>
        ) : (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              gap: '1rem',
            }}
            container
            direction="row"
            justifyContent="space-between"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleBack}
            >
              Wróć
            </MButton>
            <MButton
              style={{ width: 'fit-content' }}
              disabled={!formState.isValid}
              variant="contained"
              onClick={handleSubmit(async (values) => {
                if (orderId) {
                  const statusCode = await updateOrder(editedObject!.id, values)

                  if (statusCode === 200) {
                    await uploadImage(editedObject!.id)
                    await uploadSketch(editedObject!.id)
                    reset()
                    navigate(-1)
                  }
                } else {
                  const res = await addOrder(values)

                  if (res.statusCode === 200) {
                    await uploadImage(res.order.id)
                    await uploadSketch(res.order.id)
                    setNewOrder(res.order)
                    confirmPrintDialogControl(res.order, true)
                    reset()
                  }
                }
              })}
            >
              Zapisz
            </MButton>
          </Grid2>
        )}
      </FormContainer>
      <ConfirmPrintDialog
        open={openConfirmPrintDialog}
        closeDialog={() => confirmPrintDialogControl(null, false)}
        dataToPrint={elementToPrint}
        openDocumentGenerator={() => setOpenDocumentGeneratorDialog(true)}
      />

      <GenerateDocumentDialog
        open={openDocumentGeneratorDialog}
        addAnotherOrderToDocument={addAnotherOrderToDocument}
        generateDocument={finishDocument}
      />
      <RouteGuardDialog methods={methods as unknown as UseFormReturn<Order>} />
    </Container>
  )
}
