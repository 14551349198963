import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import { Button, Grid2, Typography } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas'

interface SignaturePadProps {
  title: string
  onEnd: (signature: string) => void
  clearSignature: (signature: string) => void
}

export interface SignaturePadHandle {
  clearSignatureCanvas: () => void
}

const SignaturePad = forwardRef<SignaturePadHandle, SignaturePadProps>(
  ({ onEnd, clearSignature, title }, ref) => {
    const signatureRef = useRef<SignatureCanvas | null>(null)

    const clearSignatureCanvas = useCallback(() => {
      signatureRef?.current?.clear()
      clearSignature('')
    }, [])

    useImperativeHandle(ref, () => ({
      clearSignatureCanvas,
    }))

    return (
      <Grid2
        direction="column"
        container
        sx={{ flex: 1 }}
        style={{ width: 'max-content' }}
      >
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
        <Grid2 container justifyContent="flex-end">
          <div
            style={{
              border: '1px solid black',
              width: '402px',
              height: '152px',
            }}
          >
            <SignatureCanvas
              ref={signatureRef}
              penColor="black"
              canvasProps={{ width: 400, height: 150 }}
              onEnd={() => {
                if (signatureRef.current) {
                  onEnd(
                    signatureRef.current
                      .getTrimmedCanvas()
                      .toDataURL('image/png'),
                  )
                }
              }}
            />
          </div>
        </Grid2>
        <Grid2 container direction="row" justifyContent="flex-end">
          <Button onClick={clearSignatureCanvas}>Wyczyść podpis</Button>
        </Grid2>
      </Grid2>
    )
  },
)

export default SignaturePad
