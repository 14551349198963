import * as yup from 'yup'

function isValidIPv4(ip: string): boolean {
  const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipv4Regex.test(ip)
}

export const loginValidation = yup.object().shape({
  login: yup.string().required('Login jest wymagany'),
  password: yup.string().required('Hasło jest wymagane'),
})

export const updateSettingsValidation = yup.object().shape({
  login: yup.string().required('Login jest wymagany'),
  oldPassword: yup.string(),
  newPassword: yup.string(),
  printerIpAddress: yup
    .string()
    .test('is-valid-ip', 'Błędny adres IP', (value) => {
      return value ? isValidIPv4(value) : false
    })
    .required('Adres IP jest wymagany'),
})

export const addOrderValidation = yup.object().shape({
  name: yup.string().required('Nazwa jest wymagana'),
  taxId: yup.string(),
  postalCode: yup
    .string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie 00-000'),
  city: yup.string().required('Miasto jest wymagane'),
  road: yup.string().required('Ulica jest wymagana'),
  ralColor: yup.string().required('Kolor RAL jest wymagany'),
  ciNumber: yup.string().required('PZ jest wymagany'),
  date: yup.number().required('Data jest wymagana'),
  phoneNumber: yup
    .string()
    .required('Numer telefonu jest wymagany')
    .matches(
      /^(\d{3}-\d{3}-\d{3}|\d{9})$/,
      'Numer telefonu musi być w formacie 000-000-000 lub 000000000',
    ),
  base: yup.string().required('Podkład jest wymagany'),
  sandblasting: yup.string().required('Pisakowanie jest wymagane'),
  signature: yup.string(),
  glossLevel: yup.string().required('Stopień połysku jest wymagany'),
  finishing: yup.string().required('Wykończenie jest wymagane'),
  paintType: yup.string().required('Rodzaj farby jest wymagany'),
  description: yup.string(),
  product: yup.string().required('Produkt jest wymagany'),
  quantity: yup
    .number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value,
    )
    .typeError('Ilość musi być liczbą')
    .required('Ilość jest wymagana'),
  quantityType: yup.string().required('Typ jest wymagany'),
})

export const addContractorValidation = yup.object().shape({
  name: yup.string().required('Nazwa jest wymagany'),
  taxId: yup.string(),
  postalCode: yup
    .string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie 00-000'),
  city: yup.string().required('Miastko jest wymagane'),
  road: yup.string().required('Ulica jest wymagana'),
  phoneNumber: yup
    .string()
    .required('Numer telefonu jest wymagane')
    .matches(
      /^(\d{3}-\d{3}-\d{3}|\d{9})$/,
      'Numer telefonu musi być w formacie 000-000-000 lub 000000000',
    ),
})
