import React, { useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import SignaturePad, { SignaturePadHandle } from './SignaturePad'

interface GenerateDocumentDialogProps {
  open: boolean
  addAnotherOrderToDocument: () => void
  generateDocument: (signature: string) => void
}

export default function GenerateDocumentDialog(
  props: GenerateDocumentDialogProps,
) {
  const [signature, setSignature] = useState<string>('')
  const signatureRef = useRef<SignaturePadHandle>(null)

  return (
    <Dialog open={props.open}>
      <DialogTitle>Generowanie PZ</DialogTitle>
      <DialogContent>
        <SignaturePad
          ref={signatureRef}
          clearSignature={(signature) => {
            setSignature(signature)
          }}
          onEnd={(signature) => {
            setSignature(signature)
          }}
          title="Podpis wystawiającego"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.addAnotherOrderToDocument}>
          Dodaj następne zlecenie do PZ
        </Button>
        <Button
          disabled={!signature}
          onClick={() => {
            props.generateDocument(signature)

            if (signatureRef.current) {
              signatureRef.current.clearSignatureCanvas()
            }
          }}
        >
          Wydrukuj PZ
        </Button>
      </DialogActions>
    </Dialog>
  )
}
