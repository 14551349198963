import axios, { AxiosResponse } from 'axios'

export const getServerUrl = (path?: string) => {
  return window.location.origin + '/' + path?.replace(/^\//, '')
}

export const toDataURL = (url: string) =>
  axios
    .get(`${window.location.origin}/${url}`, { responseType: 'blob' })
    .then((response: AxiosResponse) => {
      console.log('url::: ', url)
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(response.data)
      })
    })

export const dataUrlToFile = (dataURI: string) => {
  const arr = dataURI.split(',')
  const mime = arr[0].match(/:(.*?);/)?.[1]
  const bstr = atob(arr[1])

  const u8arr = Uint8Array.from(bstr, (char) => char.charCodeAt(0))

  return new File([u8arr], `converted-sketch-${Date.now()}.png`, { type: mime })
}

export function translateGlossLevelValue(value?: string) {
  switch (value) {
    case 'matt':
      return 'Mat'
    case 'semi-matt':
      return 'Półmat'
    case 'gloss':
      return 'Połysk'
    case 'semi-gloss':
      return 'Półpołysk'
    default:
      return ''
  }
}

export function translateFinishesValue(value?: string) {
  switch (value) {
    case 'fine-structure':
      return 'Struktura Drobna'
    case 'coarse-structure':
      return 'Struktura Gruba'
    case 'smooth':
      return 'Struktura Gładka'
    default:
      return ''
  }
}

export function translatePaintTypeValue(value?: string) {
  switch (value) {
    case 'polyester':
      return 'Poliester'
    case 'epoxy':
      return 'Epoksyd'
    case 'qualicoat':
      return 'Qualicoat'
    case 'industrial':
      return 'Przemysłowa'
    default:
      return ''
  }
}

export function translateQuantityTypeValue(value?: string) {
  switch (value) {
    case 'pieces':
      return 'szt.'
    case 'linearMeters':
      return 'mb'
    case 'squareMeters':
      return 'm²'
    default:
      return ''
  }
}

export function removeExtensionFromFileName(fileName: string) {
  const dotIndex = fileName.lastIndexOf('_')
  if (dotIndex === -1) return fileName
  return fileName.substring(0, dotIndex)
}
