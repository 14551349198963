import { Document } from '../types/document'
import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { Order } from '../types/order'
import { NotificationService } from '../service/NotificationService'
import { map } from 'lodash'

interface Store {
  documents: Document[]
  documentOrders: Order[]
  activeGrnNumber: string | null
  activeTaxId: string | null
  getDocuments: () => Promise<void>
  addDocumentOrder: (order: Order) => Promise<void>
  setActiveGrnNumber: (newActiveGrn: string) => void
  generateDocument: (signature: string) => Promise<number | undefined>
  setActiveTaxId: (newActiveTaxId: string) => void
  deleteDocument: (id: number) => void
  printDocument: (documentId: number) => void
}

const useDocumentsStore = create<Store>((set, get) => ({
  documents: [],
  activeGrnNumber: null,
  documentOrders: [],
  activeTaxId: null,

  getDocuments: async () => {
    await callApi<{ data: Document[]; message: string }>(
      '/api/documents',
      'GET',
    ).then((res) => set(() => ({ documents: res.data })))
  },

  setActiveGrnNumber: (newActiveGrn) =>
    set(() => ({ activeGrnNumber: newActiveGrn })),

  addDocumentOrder: async (order) =>
    set((state) => ({
      documentOrders: [...state.documentOrders, order],
    })),

  generateDocument: async (signature) => {
    const documentId: number | undefined = undefined
    if (get().documentOrders.length > 0) {
      const firstOrder = get().documentOrders[0]
      const contractor: Partial<Order> = {
        name: firstOrder.name,
        postalCode: firstOrder.postalCode,
        city: firstOrder.city,
        road: firstOrder.road,
        taxId: firstOrder?.taxId || '',
        phoneNumber: firstOrder.phoneNumber,
      }

      const elementsTable = map(get().documentOrders, (order) => ({
        orderId: order.id,
        name: order.product,
        quantity: order.quantity,
        quantityType: order.quantityType,
        ralColor: order.ralColor,
        base: order.base,
        sandblasting: order.sandblasting,
        glossLevel: order.glossLevel,
        finishing: order.finishing,
        paintType: order.paintType,
      }))

      const res = await callApi<{ data: { document: Document } }>(
        '/api/documents',
        'POST',
        {
          contractor,
          elementsTable,
          ciNumber: firstOrder.ciNumber,
          type: 'pdf',
          documentType: 'PZ',
          date: firstOrder.date,
          contractorSignature: firstOrder.signature,
          issuerSignature: signature,
        },
      )

      set((state) => ({
        documents: [...state.documents, res.data.document],
      }))
    }
    set(() => ({
      documentOrders: [],
      activeGrnNumber: null,
      activeTaxId: null,
    }))

    return documentId
  },

  deleteDocument: (id) =>
    callApi<{ data: { message: string } }>(
      `/api/documents/${id}`,
      'DELETE',
    ).then((res) => {
      NotificationService.showNotification(res.data.message, 'success')

      return set((state) => ({
        documents: state.documents.filter((doc) => doc.id !== id),
      }))
    }),

  setActiveTaxId: async (newActiveTaxId) =>
    set(() => ({ activeTaxId: newActiveTaxId })),

  printDocument: (documentId: number) =>
    callApi(`/api/document/print/${documentId}`, 'POST'),
}))

export default useDocumentsStore
